import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { CellType } from '@infragistics/igniteui-angular';

import { BranchStorageLocations, IdType, LocalizedSkeletonObjectType, ViewBranch } from '@supy/common';
import { GridComponent } from '@supy/components';
import { Packaging, PackagingUnit } from '@supy/packaging';

import { BaseItemInventory, BaseItemInventoryLocation } from '../../core';

export interface BaseItemInventoryLocationGridItem {
  readonly id: string;
  readonly itemLocationId: string | null;
  readonly packagingUnit: PackagingUnit | null;
  readonly parLevel: number | null;
  readonly minLevel: number | null;
  readonly storages: LocalizedSkeletonObjectType[];
  readonly baseStorages?: LocalizedSkeletonObjectType[];
}

@Component({
  selector: 'supy-base-item-inventory-locations-grid',
  templateUrl: './base-item-inventory-locations-grid.component.html',
  styleUrls: ['./base-item-inventory-locations-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseItemInventoryLocationsGridComponent {
  @ViewChild(GridComponent, { static: true }) protected readonly grid: GridComponent;

  protected readonly locationNameSearchText$ = new BehaviorSubject<string>('');
  readonly #dataChange = new BehaviorSubject<BaseItemInventoryLocationGridItem[]>([]);
  readonly data$ = combineLatest([this.#dataChange.asObservable(), this.locationNameSearchText$]).pipe(
    map(([locations, locationNameSearchText]) =>
      locations.filter(
        ({ id }) => this.getLocationName(id)?.toLowerCase().includes(locationNameSearchText.toLowerCase()),
      ),
    ),
  );

  readonly #packagingUnitsListChange = new BehaviorSubject<PackagingUnit[]>([]);
  protected readonly packagingUnitsList$ = this.#packagingUnitsListChange.asObservable();

  protected readonly branchStorageLocationsMap = new Map<string, LocalizedSkeletonObjectType[]>();
  protected isInventoryItem: boolean;
  protected userLocationsList: ViewBranch[];

  @Input() set userLocations(value: ViewBranch[]) {
    this.userLocationsList = value;

    if (value && this.isInventoryItem) {
      this.#dataChange.next(
        value.map(location => ({
          id: location.id,
          itemLocationId: null,
          packagingUnit: null,
          parLevel: null,
          minLevel: null,
          storages: [],
          baseStorages: location.storageLocations ?? [],
        })),
      );
    }
  }

  @Input() branchStorageLocations: BranchStorageLocations[] = [];
  @Input() isReadonly: boolean;

  @Input() set packagingUnitsList(packagesList: Packaging[]) {
    if (packagesList?.length) {
      const baseUnit = packagesList[0].baseUnit;

      this.#packagingUnitsListChange.next(
        PackagingUnit.deserializeList([
          {
            name: baseUnit.name,
            uomId: baseUnit.id,
          },
          ...packagesList.map(packaging => ({
            name: packaging.packageName,
            uomId: packaging.id,
            packagingId: packaging.id,
          })),
        ]),
      );
    }
  }

  @Input() set inventoryItem(inventoryItem: BaseItemInventory) {
    if (this.isInventoryItem !== !!inventoryItem) {
      this.isInventoryItem = !!inventoryItem;
      this.userLocations = this.userLocationsList;
    }

    if (inventoryItem?.locations?.length) {
      const inventoryItemStoragesMap = new Map<string, boolean>(inventoryItem.storages.map(({ id }) => [id, true]));

      this.#dataChange.next(
        inventoryItem.locations.map(location => {
          this.branchStorageLocationsMap.set(
            location.location.id,
            this.branchStorageLocations.find(({ id }) => id === location.location.id)?.children ?? [],
          );

          return {
            ...BaseItemInventoryLocation.deserialize({
              ...location,
              packagingUnit: location.packagingUnit ?? this.#packagingUnitsListChange.value[0],
            }),
            storages: structuredClone(
              this.branchStorageLocationsMap
                .get(location.location.id)
                ?.filter(({ id }) => inventoryItemStoragesMap.has(id)) ?? [],
            ),
          };
        }),
      );
    }
  }

  getLocationName(locationId: string): string | undefined {
    return this.userLocationsList?.find(({ id }) => locationId === id)?.name;
  }

  onCellEditDone(): void {
    this.#dataChange.next(this.#dataChange.value);
  }

  getStorageLocationsFromMap(locationId: string, storageIds: string[]): LocalizedSkeletonObjectType[] | undefined {
    return this.branchStorageLocationsMap.get(locationId)?.filter(({ id }) => storageIds.includes(id));
  }

  getStorageLocations(locationId: string, storageIds: string[]): LocalizedSkeletonObjectType[] | undefined {
    return this.branchStorageLocations
      .find(({ id }) => id === locationId)
      ?.children.filter(({ id }) => storageIds.includes(id));
  }

  getIds<T extends IdType>(items: T[]): string[] {
    return items.map(({ id }) => id);
  }

  onNumberValueChange(cell: CellType, $event: number | string): void {
    cell.editValue = $event === '' ? 0 : $event;
  }

  onStorageValueChange(cell: CellType, rowData: BaseItemInventoryLocationGridItem, $event: string[]): void {
    cell.editValue = this.isInventoryItem
      ? this.getStorageLocationsFromMap(rowData.id, $event)
      : this.getStorageLocations(rowData.id, $event);
  }
}
