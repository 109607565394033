import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GridPagingMode } from '@infragistics/igniteui-angular';
import { Currency, IANATimezone } from '@supy.api/dictionaries';

import { Uom } from '@supy/common';

import { ItemStockMovement } from '../../core';
import {
  getComputedCost,
  getComputedItemCost,
  getComputedQuantity,
  getComputedTotal,
  getEventLink,
  getEventType,
} from '../../helpers';
import { StockMovementRequestMetadata, StockMovementResponseMetadata } from '../../store';

@Component({
  selector: 'supy-stock-movement-grid',
  templateUrl: './stock-movement-grid.component.html',
  styleUrls: ['./stock-movement-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockMovementGridComponent {
  @Input({ required: true }) readonly stockMovement: ItemStockMovement[];
  @Input({ required: true }) readonly baseUnit: Uom;
  @Input({ required: true }) readonly currency: Currency;
  @Input({ required: true }) readonly currencyPrecision: number;
  @Input({ required: true }) readonly requestMetadata: StockMovementRequestMetadata;
  @Input({ required: true }) readonly responseMetadata: StockMovementResponseMetadata;
  @Input({ required: true }) readonly ianaTimeZone: IANATimezone;

  @Output() readonly pageChange = new EventEmitter<number>();

  protected readonly paginationMode = GridPagingMode.Remote;

  protected getComputedQuantity(stockMovement: ItemStockMovement): number {
    return getComputedQuantity(stockMovement, this.baseUnit);
  }

  protected getComputedCost(stockMovement: ItemStockMovement): number {
    return getComputedCost(stockMovement, this.baseUnit);
  }

  protected getComputedItemCost(stockMovement: ItemStockMovement): number {
    return getComputedItemCost(stockMovement, this.baseUnit);
  }

  protected getEventType(stockMovement: ItemStockMovement): string {
    return getEventType(stockMovement);
  }

  protected getComputedTotal(stockMovement: ItemStockMovement): number {
    return getComputedTotal(stockMovement, this.baseUnit);
  }

  protected getEventLink(stockMovement: ItemStockMovement): string | null {
    return getEventLink(stockMovement);
  }
}
