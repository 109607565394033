import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

import { RecipeIngredientsGrid } from '../recipe-ingredients-grid.interface';

@Component({
  selector: 'supy-full-width-add',
  template: `
    <supy-button
      *ngIf="!grid?.isReadonly"
      class="supy-full-width-add__add-row"
      color="default"
      width="full"
      (buttonClick)="grid.onRowAdding()"
    >
      <span class="supy-full-width-add__add-row-content">
        <span class="supy-full-width-add__add-row-content-label" i18n="@@inventory.recipe.grid.clickToAdd"
          >Click to add ingredient or sub-recipe</span
        >
        <supy-icon name="plus-flat-circle" title="Add" color="primary" size="medium"></supy-icon>
      </span>
    </supy-button>

    <div *ngIf="grid.ingredients?.length && !grid?.isReadonly" class="supy-full-width-add">
      <div [style.width]="'25.5%'" class="supy-full-width-add__cell" i18n="@@total">Total</div>
      <div [style.width]="grid.hideCosts ? '17%' : '8%'" class="supy-full-width-add__cell">
        {{ grid.ingredientsTotal.net | supyPrecision: grid.currencyPrecision }}
      </div>

      <div [style.width]="grid.hideCosts ? '17%' : '10%'" class="supy-full-width-add__cell">
        <supy-combo-box
          class="supy-full-width-add__combo"
          displayKey="name"
          titleKey="name"
          valueKey="id"
          placeholder="Uom"
          i18n-placeholder="@@inventory.recipe.grid.uom"
          name="select-uom-total"
          [itemHeight]="40"
          [overlaySettings]="{}"
          [multiple]="false"
          [clearable]="false"
          [list]="grid.totalUnits"
          [value]="[grid.totalUnitId]"
          (changed)="grid.totalUnitId = $event[0]"
        ></supy-combo-box>
      </div>

      <div [style.width]="grid.hideCosts ? '9%' : '8%'" class="supy-full-width-add__cell"></div>

      <div [style.width]="'8.5%'" class="supy-full-width-add__cell">
        {{ grid.ingredientsTotal.gross | supyPrecision: grid.currencyPrecision }}
      </div>

      <div *ngIf="!grid.hideCosts" [style.width]="'8%'" class="supy-full-width-add__cell">
        {{ grid.ingredientsTotal.avgCost | supyPrecision: grid.currencyPrecision }}
      </div>
    </div>
  `,
  styleUrls: ['./full-width-add.row.component.scss'],
})
export class FullWidthRowComponent implements ICellRendererAngularComp {
  protected grid: RecipeIngredientsGrid;
  private ingredientsLength = 0;

  agInit(params: ICellRendererParams & { grid: RecipeIngredientsGrid }): void {
    this.grid = params.grid;
    this.ingredientsLength = this.grid.ingredients?.length;
  }

  refresh(): boolean {
    return this.ingredientsLength !== this.grid.ingredients?.length;
  }
}
