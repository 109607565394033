import { ChangeDetectionStrategy, Component, EventEmitter, Inject, inject, Input, Output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { PermissionStrategy } from '@supy/authz';
import { SkeletonObjectType, Uom } from '@supy/common';

import { Packaging, PackagingGroup } from '../../core';
import { PackagingPermissionStrategy } from '../../permissions';

export interface PackagingDialogPayload {
  readonly item: SkeletonObjectType;
  readonly groupIndex?: number;
  readonly packageItem?: Packaging;
  readonly basePackageItem?: Packaging;
  readonly isDeletable?: boolean;
}

@Component({
  selector: 'supy-packages-grid',
  templateUrl: 'packages-grid.component.html',
  styleUrls: ['./packages-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackagesGridComponent {
  @Input() readonly item: SkeletonObjectType;
  @Input() readonly baseUnit: Uom;
  @Input() readonly packages: PackagingGroup[];
  @Input() readonly isReadonly?: boolean;
  @Input() readonly isEditable?: boolean = true;
  @Input() readonly isDeletable: boolean;
  @Output() readonly createPackageItem = new EventEmitter<PackagingDialogPayload>();
  @Output() readonly editPackageItem = new EventEmitter<PackagingDialogPayload>();
  @Output() readonly deletePackageItem = new EventEmitter<Packaging>();

  protected readonly canDeletePackaging = toSignal(inject(PackagingPermissionStrategy.Delete).isAllowed());

  constructor(@Inject(PackagingPermissionStrategy.Create) protected createPackagingStrategy: PermissionStrategy) {}

  onCreatePackageItem(payload: PackagingDialogPayload) {
    this.createPackageItem.emit(payload);
  }

  onEditPackageItem(payload: PackagingDialogPayload) {
    this.editPackageItem.emit(payload);
  }

  getParentPackaging(packaging: Packaging): Packaging | undefined {
    if (packaging.level === 0 || !this.packages.length) {
      return;
    }

    return this.packages.flatMap(({ items }) => items).find(({ id }) => id === packaging.parent?.id);
  }

  protected isLastPackageItem(packagingItem: Packaging, packageGroups: Packaging[]): boolean {
    const highestLevelOfItems = packageGroups.reduce((acc, item) => {
      if (item.level > acc) {
        acc = item.level;
      }

      return acc;
    }, 0);

    const packagingGroupsWithBase = this.packages.flatMap(({ items }) => items).filter(({ level }) => level === 0);

    if (packageGroups.length === 1 && packagingGroupsWithBase.length === 1) {
      return false;
    }

    return packagingItem.level === highestLevelOfItems;
  }
}
