import { IdType, LocalizedData, SimpleUser, Uom } from '@supy/common';
import { PackagingGroup, PackagingUnit } from '@supy/packaging';

import { BaseItemInventory, InventoryItemType } from '../../../core';

export enum InventoryRecipeType {
  Finished = 'finished',
  SemiFinished = 'semi-finished',
  SubRecipe = 'sub-recipe',
}

export enum InventoryRecipeStateEnum {
  Available = 'available',
  Archived = 'archived',
  Draft = 'draft',
  Deleted = 'deleted',
}

export class InventoryRecipeCookbook {
  cookTimeInMinutes?: number;
  portionSize?: string;
  servingPersons?: number;
  difficulty?: number;
  steps?: string[];
  instructions?: string;
  hidden?: boolean;
}

export interface InventoryRecipeCookBookStep {
  readonly id: string;
  readonly description: string | null;
}

export interface InventoryRecipeCategory {
  readonly id: string;
  readonly name: string;
}
export interface InventoryRecipe {
  readonly id: string;
  readonly code?: string;
  readonly imageUrl?: string;
  readonly name: LocalizedData;
  readonly type: InventoryRecipeType;
  readonly category: InventoryRecipeCategory;
  readonly storages: IdType[];
  readonly baseUnit: Uom;
  readonly isStockable: boolean;
  readonly packages: PackagingGroup[];
  readonly storageUnit: Uom;
  readonly storageToBase: number;
  readonly state?: InventoryRecipeStateEnum;
  readonly ingredients: InventoryIngredient[];
  readonly ingredientsQuantityPerAtom?: number;
  readonly metadata?: InventoryRecipeMetadata;
  readonly activeSalesTypes: ActiveSalesType[];
  readonly createdBy?: SimpleUser;
  readonly updatedBy?: SimpleUser;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  readonly item?: BaseItemInventory;
  readonly retailerItem?: IdType;
  readonly portionSize?: InventoryRecipePortionSize;
  readonly costCenters?: InventoryRecipeCostCenter[];
  readonly updates?: InventoryRecipeUpdateEvent[];
  readonly cookbook?: InventoryRecipeCookbook;
  readonly locationsIds: string[];
  readonly locations: RepositoryRecipeLocation[];
  readonly isForRevenueTracking?: boolean;
  readonly ingredientCount: number;
  readonly cost?: number;
  readonly lastPurchaseCost?: number;
}

export interface SemiFinishedRecipe extends InventoryRecipe {}

export interface FinishedRecipe extends Omit<InventoryRecipe, 'ingredients'> {
  readonly ingredients: FinishedRecipeIngredient[];
}

export interface ActiveSalesType {
  id: string;
  isDefault: boolean;
}

export interface FinishedRecipeIngredient {
  salesTypeId: string;
  ingredients: InventoryIngredient[];
}

export interface SalesTypeCost {
  readonly salesTypeId: string;
  readonly salesTypeName: string;
  readonly cost: number;
  readonly purchaseCost: number;
}
export interface RepositoryRecipeLocation {
  readonly locationId: string;
  readonly useInProduction?: boolean;
  readonly cost: number;
  readonly lastPurchaseCost: number;
  readonly salesTypesCosts: SalesTypeCost[];
}

export interface InventoryRecipeCostCenter {
  readonly location: IdType;
  readonly branch: IdType;
  readonly targetCost?: number;
  readonly costThreshold?: number;
  readonly revenuePercentage?: number;
  readonly taxes?: IdType[];
  readonly sellingPrice?: number;
  readonly 'location.id'?: string;
  readonly 'branch.id'?: string;
}

export class InventoryRecipePortionSize {
  readonly size: number;
  readonly uom: Uom;
}

export interface InventoryRecipeUpdateEvent {
  readonly userId: string;
  readonly user: SimpleUser;
  readonly status: string;
  readonly createdAt: Date;
}

export interface InventoryIngredientQuantity {
  readonly net: number;
  readonly gross?: number;
  readonly unit: PackagingUnit;
  readonly wastagePercentage?: number;
}

export interface InventoryIngredientScope {
  readonly type: InventoryItemType;
  readonly referenceId: string;
}

export interface InventoryIngredient {
  readonly baseUnit?: Uom;
  readonly cost?: number;
  readonly id: string;
  readonly includedInCost?: boolean;
  readonly lastPurchaseCost?: number;
  readonly name?: LocalizedData;
  readonly netQuantity?: number;
  readonly quantity?: InventoryIngredientQuantity;
  readonly scope?: InventoryIngredientScope;
  readonly modifier: boolean;
  readonly packagings: PackagingUnit[];
}

export interface InventoryRecipeMetadata {
  readonly cookTimeInMinutes?: number;
  readonly portionSize?: string;
  readonly yield?: number;
  readonly servingPersons?: number;
  readonly difficulty?: number;
  readonly steps?: string[];
  readonly instructions?: string;
}

export type RecipeSection = 'repository' | 'inventory';

export type AutocompleteFinishedRecipe = Pick<InventoryRecipe, 'id' | 'name' | 'locationsIds'>;
